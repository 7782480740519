@import "color";

.input {
  width: 100%;
  max-width: 240px;
  padding: 25px 20px;
  border: 1px solid rgba(255, 255, 255, 0.2);
  border-radius: 5px;
  background: rgba(255, 255, 255, 0.1);
  font-size: 1.8rem;
  font-weight: 500;
  line-height: 150%;
  color: $main-text;
  &::placeholder {
    font-size: 1.8rem;
    color: rgba(255, 255, 255, .6);
    @media screen and (max-width: 767px) {
      font-size: 1.5rem;
    }
  }
  @media screen and (max-width: 767px) {
    font-size: 1.5rem;
  }
}

