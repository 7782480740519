@import "color";

.header {
  @media screen and (max-width: 1199px) {
    height: 145px;
  }
  &__wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 20px 0;
    @media screen and (max-width: 1199px) {

    }
    @media screen and (max-width: 767px) {
      padding: 10px 0;
    }
  }

  &__logo {
    display: flex;
    align-items: center;
    color: $main-text;
  }

  .logo-icon {
    width: 73px;
    height: 73px;
    @media screen and (max-width: 767px) {
      width: 41px;
      height: 41px;
    }
  }

  .logo-text {
    display: flex;
    flex-direction: column;
    align-items: start;
    margin-left: 14px;
    @media screen and (max-width: 767px) {
      margin-left: 7px;
    }
  }

  .logo-title {
    font-size: 2.6rem;
    font-weight: 700;
    line-height: 110%;
    @media screen and (max-width: 767px) {
      font-size: 1.5rem;
    }
  }

  .logo-subtitle {
    max-width: 218px;
    margin-top: 3px;
    font-size: 1.4rem;
    font-weight: 500;
    line-height: 140%;
    @media screen and (max-width: 767px) {
      max-width: 108px;
      font-size: .9rem;
    }
  }

  &__menu {
    max-width: 470px;
    width: 100%;
    height: 70px;
    @media screen and (max-width: 1199px) {
      position: absolute;
      top: 110px;
      left: 0;
      z-index: 15;
      max-width: 100%;
      &.active {
        position: fixed;
        top: 0;
        background: linear-gradient(180deg, rgb(12, 18, 38) 75%,rgba(12, 18, 38, 0) 100%);
      }
    }
    @media screen and (max-width: 960px) {
      overflow: auto;
    }
    @media screen and (max-width: 767px) {
      top: 65px;
      height: 50px;
    }
  }

  .menu-list {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    width: 100%;
    height: 100%;
    &:after {
      content: '';
      display: block;
      width: 35%;
      @media screen and (max-width: 1199px) {
        display: none;
      }
    }
    @media screen and (max-width: 1199px) {
      align-items: center;
      padding: 0 20px;
    }
    @media screen and (max-width: 960px) {
      width: 125vw;
    }
    @media screen and (max-width: 540px) {
      width: 155vw;
    }
    @media screen and (max-width: 425px) {
      width: 180vw;
    }
  }

  .menu-item {
    position: relative;
    font-size: 1.9rem;
    font-weight: 400;
    line-height: 150%;
    color: $main-text;
    &:after {
      content: '';
      position: absolute;
      bottom: -5px;
      left: 0;
      width: 100%;
      height: 1px;
      background-color: rgba(245, 245, 245, .2);
    }
    &.active {
      position: relative;
      &:after {
        height: 2px;
        background-color: rgba(245, 245, 245, 1);
      }
    }
    @media screen and (max-width: 767px) {
      font-size: 1.3rem;
    }
  }

  .item-link {
    font-size: 1.9rem;
    font-weight: 400;
    line-height: 150%;
    color: $main-text;
    @media screen and (max-width: 767px) {
      font-size: 1.3rem;
    }
  }

  &__contacts {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: end;
  }

  .phone-link {
    font-size: 2.6rem;
    font-weight: 700;
    line-height: 110%;
    color: $main-text;
    @media screen and (max-width: 767px) {
      font-size: 1.4rem;
    }
  }

  .dropdown-btn {
    position: relative;
    margin-top: 13px;
    font-size: 1.9rem;
    font-weight: 400;
    line-height: 150%;
    border-bottom: 1px solid rgba(245, 245, 245, 0.2);
    &:hover {
      .dropdown-menu {
        border-radius: 20px;
        transform-origin: right top;
        transform: scale(1.5);
        z-index: 15;
        @media screen and (max-width: 767px) {
          border-radius: 10px;
        }
      }
    }
    @media screen and (max-width: 767px) {
      font-size: 1rem;
    }
  }

  .dropdown-menu {
    position: absolute;
    top: 100%;
    right: 0;
    display: flex;
    flex-direction: column;
    width: 245px;
    padding: 25px 20px;
    transform-origin: right top;
    transform: scale(0);
    background-color: $main-text;
    transition: .3s ease;
    @media screen and (max-width: 767px) {
      width: 160px;
      padding: 15px 10px;
    }
  }

  .dropdown-item {
    font-size: 1.8rem;
    font-weight: 400;
    line-height: 150%;
    color: $body-color;
    @media screen and (max-width: 767px) {
      font-size: 1.2rem;
    }
  }
}