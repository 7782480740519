@import "color";

.main-btn {
  padding: 25px 35px 25px 35px;
  border-radius: 93.6px;
  background: $gradient-static;
  color: $main-text;
  font-size: 2rem;
  font-weight: 500;
  line-height: 150%;
  transition: .2s linear;
  cursor: pointer;
  &:hover{
    transform: scale(1.04);
    box-shadow: 0 4.16px 41.6px 0 rgba(248, 140, 64, 0.2);
  }
  @media screen and (max-width: 767px) {
    padding: 15px 25px 15px 25px;
    font-size: 1.4rem;
  }
}

.button-prev,
.button-next {
  position: absolute;
  top: 50%;
  transform: translate(0, -50%);
  z-index: 5;
  width: 70px;
  height: 70px;
  border-radius: 50%;
  border: 1px solid rgba(255, 255, 255, 0.1);
  backdrop-filter: blur(44px);
  background: rgba(255, 255, 255, 0.1);
  cursor: pointer;
  &:before {
    content: '';
    position: absolute;
    top: 50%;
    width: 30%;
    height: 30%;
  }
  @media screen and (max-width: 767px) {
    width: 50px;
    height: 50px;
  }
}

.button-prev {
  left: 20px;
  &:before {
    left: 30px;
    border-left: 1px solid $main-text;
    border-bottom: 1px solid $main-text;
    transform: translate(0, -50%) rotate(45deg);
    @media screen and (max-width: 767px) {
      left: 20px;
    }
  }
  @media screen and (max-width: 767px) {
    left: 5px;
  }
}

.button-next {
  right: 20px;
  &:before {
    right: 30px;
    border-right: 1px solid $main-text;
    border-bottom: 1px solid $main-text;
    transform: translate(0, -50%) rotate(-45deg);
    @media screen and (max-width: 767px) {
      right: 20px;
    }
  }
  @media screen and (max-width: 767px) {
    right: 5px;
  }
}