@import "container";
@import "color";
@import "title";
@import "buttons";
@import "form";
@import "header";
@import "main";
@import "footer";


* {
  outline: none;
  box-sizing: border-box;

  &:after,
  &:before {
    transition: inherit;
  }
}

html {
  scroll-behavior: smooth;
  overflow-x: hidden;
}


body {
  font-family: "Rubik", sans-serif;
  font-optical-sizing: auto;
  font-style: normal;
  overflow: hidden;
  background-color: $body-color;
  color: $main-text;
}

:root {
  font-size: 10px;
}

h1, h2, h3, h4, h5, h6, p, ul, li {
  margin: 0;
  padding: 0;
  list-style: none;
}

a {
  text-decoration: none;
  cursor: pointer;
  transition: .3s;
}

input,
button,
textarea {
  outline: none;
  background: none;
  border: none;
  padding: 0;
  cursor: pointer;
  transition: .3s;
  outline: 0;
}

.strong {
  font-weight: 700;
}

.accent {
  color: #FA8C41;
}

.section-title {
  display: block;
  margin-bottom: 20px;
  font-size: 1.7rem;
  font-weight: 400;
  line-height: 150%;
  color: $green-text;
  text-transform: uppercase;
  @media screen and (max-width: 1199px) {
    text-align: center;
  }
  @media screen and (max-width: 767px) {
    font-size: 1.2rem;
  }
}
